/*
 * @Auth: linjituan
 * @Date: 2022-10-19 16:44:34
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-10-13 20:50:14
 */
import '@/assets/plugins/iot-sdk.js'
import JSEncrypt from 'jsencrypt'
import { webApplyDevice, webReturnDevice, getPrivateKey } from '@/api/iot/cameraDevice'
var Engine = window.AliDeviceRtcEngine.Engine
var LogStore = {
  add: function (str) {
    console.log(str)
  }
}

export default {
  props: {
    // rtcInfo: {
    //   type: Object,
    //   default: () => {}
    // },
    vehicleCameraDeviceInfo: {
      type: Object,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      aliWebrtc: null,
      channelId: null,
      hasConnect: false,
      videoId: '',
      rtcAppId: '',
      rtcInfo: {}
    }
  },
  methods: {
    async openVoice() {
      if (!this.rtcInfo || !this.rtcInfo.webCameraDeviceInfo) {
        this.rtcInfo = await this.webApplyDevice()
      }
      this.rtcAppId = this.rtcInfo.webCameraDeviceInfo.id
      await this.connectWeb('voice')
      this.callPhone('audio')
    },
    async openVideo() {
      this.$emit('onConnectType', 'init')
      if (!this.rtcInfo || !this.rtcInfo.webCameraDeviceInfo) {
        this.rtcInfo = await this.webApplyDevice()
      }
      console.log('openVideo **** 1', this.rtcInfo)
      console.log('openVideo **** 2', this.vehicleCameraDeviceInfo)
      this.rtcAppId = this.rtcInfo.webCameraDeviceInfo.id
      this.videoId = this.rtcInfo.webCameraDeviceInfo.deviceName
      await this.connectWeb()
      this.handleMuteMic()
      this.handleMuteCamera()
      this.callPhone()
    },
    refresh() {
      console.log('refresh **** ')
      this.handleTerminate()
      this.aliWebrtc.deinit()
      setTimeout(() => {
        this.init()
      }, 50)
    },
    webApplyDevice() {
      return new Promise((resolve) => {
        webApplyDevice({ vehicleId: this.vehicleId }).then(async (res) => {
          res.data.webCameraDeviceInfo.deviceSecret = await this.rsaUncrypted(res.data.webCameraDeviceInfo)
          console.log('webApplyDevice **** 1', res.data)
          resolve(res.data)
        })
      })
    },
    webReturnDevice() {
      return new Promise((resolve) => {
        webReturnDevice({ id: this.rtcAppId }).then((res) => {
          resolve()
        })
      })
    },
    connectWeb(type) {
      return new Promise((resolve) => {
        this.aliWebrtc = new Engine({
          instanceId: this.rtcInfo.instanceId,
          productKey: this.rtcInfo.productKey,
          deviceName: this.rtcInfo.webCameraDeviceInfo.deviceName,
          deviceSecret: this.rtcInfo.webCameraDeviceInfo.deviceSecret
        })
        this.aliWebrtc.disableLocalAudioPublish(type !== 'voice')
        this.aliWebrtc.disableLocalVideoPublish(true)
        this.registerOn()
        this.aliWebrtc.init()
        const that = this
        // aliWebrtc.startPreview('preview_video')
        this.aliWebrtc.onError(function (error) {
          console.log('OnError', error)
          LogStore.add('【异常信息】' + (error.message || '未知异常'))
          if (error.message === '音视频增值服务实例通信时长已消耗完') {
            that.$message.warn('低延迟实时视频（RTC）额度用尽，如还需更多额度，请联系管理员', 3)
          }
          if (error.code === 6840) {
            that.$emit('onConnectType', 'error')
          } else if (error.message === 'heartbeat timeout') {
            if (this.hasConnect) {
              that.refresh()
            }
          }
        })
        resolve()
      })
    },
    async callPhone(type) {
      this.channelId = await this.aliWebrtc.launchChannel(
        this.rtcInfo.productKey, // 对端设备的productKey。
        this.vehicleCameraDeviceInfo.deviceName, // 对端设备的deviceName。
        type || 'video', // video或audio。
        true, // 是否高清通话。
        this.rtcInfo.webCameraDeviceInfo.deviceName // 视频通话视图。
      )
      console.log('callPhone **** ')
    },
    registerOn() {
      var engine = this.aliWebrtc
      engine.on('accept', this.onAccept)
      engine.on('reject', this.onReject)
      engine.on('busy', this.onBusy)
      engine.on('leave', this.onLeave)
      engine.on('calling', this.onCalling)
      engine.on('networkQuality', this.onNetworkQuality)
      engine.on('userLeave', this.onUserLeave)
      engine.on('join', this.onJoin)
      engine.on('disconnect', this.onDisconnect)
      engine.on('connect', this.onConnect)
    },
    handleTerminate() {
      // clearTimeout(responseTimer.current)
      if (this.channelId) {
        // 结束通话。
        this.hasConnect = false
        this.aliWebrtc.cancelChannel(this.channelId)
        // clearInterval(timer.current)
        LogStore.add('主叫设备结束通话')
      }
    },
    onReject() {
      LogStore.add('被叫设备拒听通话')
    },
    onBusy() {
      LogStore.add('被叫设备忙线')
    },
    onLeave() {
      LogStore.add('被叫设备挂断')
      console.log('onLeave', this.rtcInfo)
      if (this.rtcInfo && this.rtcInfo.webCameraDeviceInfo) {
        this.callPhone()
      }
    },
    onAccept(res) {
      LogStore.add('被叫设备接听通话', res)
      if (this.checkJoinTimeOut) {
        clearTimeout(this.checkJoinTimeOut)
      }
    },
    // 主叫时，被呼叫直接返回忙线状态。
    onCalling(response) {
      const { callerIotId, channelId } = response
      this.aliWebrtc.guestResponse('busy', callerIotId, channelId)
      LogStore.add('主叫设备忙线', response)
    },
    // 网络质量。
    onNetworkQuality(data) {
      // console.log('网络质量。', data)
    },
    onUserLeave(data) {
      this.handleTerminate()
      LogStore.add('被叫设备断线')
    },
    onJoin(info) {
      console.log('info ***', info)
      const { appid, channel } = info
      LogStore.add(`被叫设备加入频道：${channel}, 应用id: ${appid}`)
      if (appid) {
        this.hasConnect = true
        this.$emit('onConnectType', 'join')
      }
    },
    onDisconnect(data) {
      console.log('断开连接回调', data)
      if (this.hasConnect) {
        this.hasConnect = false
        this.refresh()
      }
      // this.$emit('onConnectType', 'disconnect')
    },
    onConnect(data) {
      console.log('连接成功回调', data)
      this.checkJoinTimeOut = setTimeout(() => {
        this.refresh()
      }, 30 * 1000)
    },
    handleMuteMic() {
      this.aliWebrtc.disableLocalAudioPublish(true)
      LogStore.add(`关闭麦克风`)
    },

    handleMuteCamera() {
      this.aliWebrtc.disableLocalVideoPublish(true)
      LogStore.add(`关闭摄像头`)
    },
    closeVoice() {},
    rsaUncrypted(item) {
      const encrypted = item.deviceSecret
      var decrypt = new JSEncrypt() // 创建解密对象实例
      return new Promise((resolve) => {
        // 之前ssl生成的秘钥
        getPrivateKey({
          deviceName: item.deviceName
        }).then((res) => {
          var priKey = res.data
          decrypt.setPrivateKey(priKey) // 设置秘钥
          var uncrypted = decrypt.decrypt(encrypted) // 解密之前拿公钥加密的内容
          console.log('uncrypted', uncrypted)
          resolve(uncrypted)
        })
      })
    }
  },
  beforeDestroy() {
    if (this.checkJoinTimeOut) {
      clearTimeout(this.checkJoinTimeOut)
    }
    this.handleTerminate()
    this.aliWebrtc && this.aliWebrtc.deinit()
    this.rtcAppId && this.webReturnDevice()
  }
}
